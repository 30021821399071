import { ProvidedGuestDetail } from "@/api/guest/types";
import PaymentMethodItem from "@/components/commons/PaymentMethodItem.vue";
import { K_WEDDING_GIFT_PAYMENT_METHODS } from "@/constants";
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "WeddingGiftSection",
  components: {
    PaymentMethodItem,
  },
  setup() {
    const { guestDetail } = inject("guestDetail") as ProvidedGuestDetail;
    return {
      guestDetail,
      paymentMethods: K_WEDDING_GIFT_PAYMENT_METHODS,
    };
  },
});
