import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";

export default defineComponent({
  name: "MediaPlayer",
  setup(_, { emit }) {
    const audioPlayer = ref();
    const playing = ref(false);
    const lastPlayingState = ref(true);

    const pause = () => {
      playing.value = false;
      audioPlayer.value.pause();
    };
    const play = () => {
      playing.value = true;
      audioPlayer.value.play();
    };
    const togglePlayer = () => {
      if (playing.value) {
        pause();
      } else {
        play();
      }
    };
    const listenVisibilityChangeEvent = () => {
      if (document.visibilityState !== "visible") {
        lastPlayingState.value = playing.value;
        pause();
        return;
      }
      if (lastPlayingState.value) {
        play();
      }
    };

    onMounted(async () => {
      emit("registerPlayer", { play, pause });
      document.addEventListener(
        "visibilitychange",
        listenVisibilityChangeEvent
      );
    });
    onBeforeUnmount(() => {
      document.removeEventListener(
        "visibilitychange",
        listenVisibilityChangeEvent
      );
    });

    return {
      audioPlayer,
      playing,
      togglePlayer,
    };
  },
});
