<template>
  <div class="home-page">
    <div class="home-page__navigation">
      <div
        v-for="menu in menus"
        :key="menu.text"
        class="home-page__navigation__menu"
        @click="warpTo(menu.className)"
      >
        {{ menu.text }}
      </div>
    </div>
    <div class="home-page__content-wrapper">
      <div class="home-page__content-wrapper__ornament">
        <img src="@/assets/images/background/homepage-ornament-1.png" alt="" />
        <img src="@/assets/images/background/homepage-ornament-2.png" alt="" />
        <img src="@/assets/images/background/homepage-ornament-1.png" alt="" />
        <img src="@/assets/images/background/homepage-ornament-2.png" alt="" />
      </div>
      <div class="home-page__content">
        <div class="home-page__content__hashtag">#deDIKAtedtoGISEL</div>
        The wedding of
        <img src="@/assets/images/names.png" class="home-page__content__name" />
        <div class="home-page__content__date">06.01.2024</div>
      </div>
    </div>
  </div>
</template>
<script src="./ts/home-page.ts"></script>

<style lang="scss">
@import "@/assets/scss/tokens";
.home-page {
  background-color: $color-green;
  display: flex;
  min-height: 100vh;
  &__navigation {
    background-color: white;
    box-shadow: $default-shadow;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    &__menu {
      padding: 8px 16px;
      height: 34px;
      cursor: pointer;
      &:hover {
        background-color: $color-base;
        border-bottom: solid 2px $red-dark;
      }
    }
  }
  &__content-wrapper {
    position: relative;
    margin: auto;
    max-height: 90%;
    &__ornament {
      img {
        position: absolute;
        &:nth-child(1),
        &:nth-child(3) {
          width: 120px;
          height: auto;
          --pos-x: -50px;
          --pos-y: -65px;
        }
        &:nth-child(1) {
          top: var(--pos-y);
          left: var(--pos-x);
        }
        &:nth-child(3) {
          bottom: var(--pos-y);
          right: var(--pos-x);
        }
        &:nth-child(2),
        &:nth-child(4) {
          width: 40px;
          height: auto;
          --pos-x: -26px;
          --pos-y: -26px;
        }
        &:nth-child(2) {
          top: var(--pos-y);
          right: var(--pos-x);
        }
        &:nth-child(4) {
          bottom: var(--pos-y);
          left: var(--pos-x);
          transform: rotate(180deg);
        }
      }
    }
  }
  &__content {
    background-color: white;
    font-size: 14px;
    padding: 16px;
    border-radius: 24px;
    box-shadow: $default-shadow;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    &__name {
      width: 424px;
      height: auto;
      max-width: calc(100vw - 152px);
      max-height: 300px;
    }
    &__date {
      position: absolute;
      bottom: 24px;
    }
    &__hashtag {
      position: absolute;
      top: 24px;
    }
  }
}
@media only screen and (max-width: 400px) {
  .home-page {
    &__content-wrapper {
      &__ornament {
        img {
          &:nth-child(1),
          &:nth-child(3) {
            width: 80px;
            --pos-x: -30px;
            --pos-y: -45px;
          }
          &:nth-child(2),
          &:nth-child(4) {
            width: 30px;
            --pos-x: -18px;
            --pos-y: -18px;
          }
        }
      }
    }
    &__content {
      &__name {
        max-width: 70vw;
      }
    }
  }
}
@media only screen and (min-width: 1024px) {
  .home-page {
    &__content {
      font-size: 24px;
      padding: 24px;
      &__date {
        bottom: 24px;
      }
    }
  }
}
</style>
