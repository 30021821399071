import { ProvidedGuestDetail } from "@/api/guest/types";
import HolyMatrimonySection from "@/components/contents/HolyMatrimonySection.vue";
import WeddingReceptionSection from "@/components/contents/WeddingReceptionSection.vue";
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "SaveTheDateSection",
  components: {
    HolyMatrimonySection,
    WeddingReceptionSection,
  },
  setup() {
    const { guestDetail } = inject("guestDetail") as ProvidedGuestDetail;
    return {
      guestDetail,
    };
  },
});
