<template>
  <div class="invitation-content">
    <HomePage />
    <ProfileSection />
    <CountDownSection />
    <SaveTheDateSection />
    <GallerySection />
    <RsvpSection v-if="guestDetail.maxNumberOfGuests" />
    <WishSection />
    <WeddingGiftSection />
    <div class="thank-you-section">
      <img src="@/assets/images/thank-you.jpg" alt="" />
      <div class="thank-you-section__text">Thank You</div>
    </div>
  </div>
</template>
<script src="./ts/invitation-content.ts"></script>

<style lang="scss" scoped>
@import "@/assets/scss/font";
.invitation-content {
  font-size: 14px;
  line-height: 1.33;
  position: relative;
  --page-padding: 24px;
  & > *:not(.thank-you-section):not(.media-player) {
    padding: var(--page-padding);
    width: 100%;
  }
  .thank-you-section {
    position: sticky;
    bottom: 0;
    z-index: -1;
    width: 100%;
    padding: 8px;
    &__text {
      @include section-title;
      position: absolute;
      bottom: 24px;
      left: 50%;
      font-size: 12vw;
      line-height: 0.8;
      transform: translate(-50%);
      color: white;
      filter: drop-shadow(0px 0px 2px #818181);
    }
  }
}
</style>
