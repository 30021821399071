<template>
  <Suspense>
    <template #default>
      <InvitationContent />
    </template>
    <template #fallback>
      <span>Loading...</span>
    </template>
  </Suspense>
  <transition name="envelope">
    <InvitationEnvelope v-if="visibleEnvelope" @open="openEnvelope" />
  </transition>
  <MediaPlayer
    class="player"
    :class="{ 'player-hidden': !visibleMediaPlayer }"
    @registerPlayer="registerMediaPlayer"
  />
</template>

<script src="./app.ts"></script>

<style lang="scss">
@import "./assets/scss/tokens";
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-primary;
}
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: $red;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}
img {
  width: 100%;
}
.envelope-leave-active {
  transition: top 0.5s ease;
}
.envelope-leave-to {
  top: -100%;
}
</style>
