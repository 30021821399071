import { K_HOLY_MATRIMONY_DATE_STRING } from "@/constants";
import { convertTimeToCountdown } from "@/utils/time";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "CountDownSection",
  computed: {},
  methods: {},
  filters: {},
  setup() {
    const dDate = new Date(K_HOLY_MATRIMONY_DATE_STRING);
    const countdownTime = ref(0);

    const updateCountdown = () => {
      const now = new Date();
      countdownTime.value = dDate.getTime() - now.getTime();
      setTimeout(updateCountdown, 1000);
    };
    const formatTime = (value: number) => {
      return String(value).padStart(2, "0");
    };

    onMounted(() => {
      updateCountdown();
    });

    const title = computed(() => {
      if (hasPassedCountdown.value) {
        return "We've been married for";
      }
      return "Please celebrate with us in";
    });
    const countdown = computed(() => {
      return convertTimeToCountdown(Math.abs(countdownTime.value));
    });
    const hasPassedCountdown = computed(() => {
      return countdownTime.value < 0;
    });

    return {
      title,
      countdown,
      formatTime,
    };
  },
});
