import { loadGuestWishesList, upsertGuestWish } from "@/api/guest/api";
import { GuestWish, ProvidedGuestDetail } from "@/api/guest/types";
import { APIError, APIResponse } from "@/types";
import { computed, defineComponent, inject, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "WishSection",
  async setup() {
    const { guestDetail, updateGuestDetail } = inject(
      "guestDetail"
    ) as ProvidedGuestDetail;
    const guestWishes = ref<GuestWish[] | APIError>([]);
    const wish = ref<string>(guestDetail.value.wish?.message || "");
    const name = ref<string>(guestDetail.value.wish?.name || "");
    const guestName = computed<string>(() => {
      if (!guestDetail?.value?.name || !guestDetail.value.autoFillWishName) {
        return "";
      }
      return guestDetail?.value?.name.replace("& Partner", "");
    });
    const savedName = computed(() => guestName.value || name.value);

    const loadGuestWishes = () => {
      loadGuestWishesList().then(
        (res: APIResponse<GuestWish[]>) => (guestWishes.value = res)
      );
    };
    const submit = async () => {
      if (wish.value === guestDetail.value.wish?.message) {
        return;
      }
      await upsertGuestWish({
        guestKey: guestDetail?.value?.key || "",
        name: savedName.value,
        wish: wish.value,
      }).then(() => {
        updateGuestDetail({
          wish: {
            name: name.value,
            message: wish.value,
          },
        });
      });
      console.log("submitting wishes", wish.value, "by", savedName);
      loadGuestWishesList().then(
        (res: APIResponse<GuestWish[]>) => (guestWishes.value = res)
      );
    };

    onMounted(loadGuestWishes);
    const unwatchGuestWish = watch(
      () => guestDetail.value.wish,
      () => {
        name.value = guestDetail.value.wish?.name || "";
        wish.value = guestDetail.value.wish?.message || "";
        unwatchGuestWish();
      }
    );

    return {
      wish,
      guestWishes,
      name,
      guestName,
      savedName,
      submit,
    };
  },
});
