import { ProvidedGuestDetail } from "@/api/guest/types";
import CountDownSection from "@/components/contents/CountDownSection.vue";
import GallerySection from "@/components/contents/GallerySection.vue";
import HomePage from "@/components/contents/HomePage.vue";
import ProfileSection from "@/components/contents/ProfileSection.vue";
import RsvpSection from "@/components/contents/RsvpSection.vue";
import SaveTheDateSection from "@/components/contents/SaveTheDateSection.vue";
import WeddingGiftSection from "@/components/contents/WeddingGiftSection.vue";
import WishSection from "@/components/contents/WishSection.vue";
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "InvitationContent",
  components: {
    HomePage,
    ProfileSection,
    RsvpSection,
    WishSection,
    CountDownSection,
    GallerySection,
    SaveTheDateSection,
    WeddingGiftSection,
  },
  setup() {
    const { guestDetail } = inject("guestDetail") as ProvidedGuestDetail;

    return {
      guestDetail,
    };
  },
});
