<template>
  <div class="wedding-gift-section">
    <div class="wedding-gift-section__title">Virtual Blessings</div>
    <div class="wedding-gift-section__message">
      {{ guestDetail.name || "Our dear guests" }}, we look forward for your
      prayers{{ guestDetail.invitationType > 1 ? " and attendance" : "" }}.
    </div>
    <div class="wedding-gift-section__message">
      However, if you wish to send blessings through other means, we also
      provide digital envelope to make it easier for you:
    </div>
    <div class="wedding-gift-section__payment-methods">
      <div
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.label"
        class="wedding-gift-section__payment-methods__item"
      >
        <PaymentMethodItem :paymentMethod="paymentMethod" />
      </div>
    </div>
  </div>
</template>
<script src="./ts/wedding-gift-section.ts"></script>

<style lang="scss">
@import "@/assets/scss/tokens";
@import "@/assets/scss/font";
.wedding-gift-section {
  background-color: white;
  background-image: url("@/assets/images/background/leaf.png");
  background-repeat: repeat;
  &__title {
    @include section-title;
  }
  &__message {
    text-align: center;
  }
  &__payment-methods {
    background-color: white;
    box-shadow: $default-shadow;
    border-radius: 8px;
    margin: 16px auto 0;
    padding: 16px;
    max-width: 700px;
    &__item:not(:first-child) {
      margin-top: 16px;
    }
  }
}
</style>
