<template>
  <div class="rsvp-section">
    <div class="rsvp-section__wrapper">
      <div class="rsvp-section__title">RSVP</div>
      <div class="rsvp-section__name">
        <div class="rsvp-section__name__label">Dear,</div>
        <div class="rsvp-section__name__value">
          {{ guestDetail?.name }}
        </div>
      </div>
      <div v-if="guestDetail.rsvp && !editing" class="rsvp-section__thank-you">
        <div class="rsvp-section__thank-you__message">
          Thank you for your confirmation!
          <div>
            Attend:
            <strong v-if="!churchAttendance && !receptionAttendance">No</strong>
            <template v-else>
              <strong v-if="churchAttendance">
                Holy matrimony -
                {{ churchNumberOfGuests }}
                {{ churchNumberOfGuests <= 1 ? "person" : "people" }}
              </strong>
              <template v-if="churchAttendance && receptionAttendance">
                &
              </template>
              <strong v-if="receptionAttendance">
                Reception -
                {{ receptionNumberOfGuests }}
                {{ receptionNumberOfGuests <= 1 ? "person" : "people" }}
              </strong>
            </template>
          </div>
        </div>
        <div class="rsvp-section__thank-you__edit-button">
          <button @click="startEdit">Change</button>
        </div>
      </div>
      <template v-else>
        <div class="rsvp-section__instruction">
          Please kindly help us prepare everything better by confirming your
          attendance with this RSVP form:
        </div>
        <div class="rsvp-section__form">
          <div class="rsvp-section__form__confirmation">
            <div
              v-if="visibleHolyMatrimonySection"
              class="rsvp-section__form__confirmation__item rsvp-section__form__confirmation__item--holy-matrimony"
            >
              Will you attend the <b>holy matrimony</b>?
              <RsvpItem
                v-model:attendance="churchAttendance"
                v-model:numberOfGuests="churchNumberOfGuests"
              />
            </div>
            <img
              v-if="guestDetail?.invitationType === 4"
              src="@/assets/images/separator.png"
              alt=""
              class="rsvp-section__form__confirmation__separator"
            />
            <div
              v-if="visibleReceptionSection"
              class="rsvp-section__form__confirmation__item rsvp-section__form__confirmation__item--reception"
            >
              Will you attend the <b>reception</b>?
              <RsvpItem
                v-model:attendance="receptionAttendance"
                v-model:numberOfGuests="receptionNumberOfGuests"
              />
            </div>
          </div>
          <div
            v-if="
              (receptionAttendance || churchAttendance) &&
              maxNumberOfGuests === 1
            "
            class="rsvp-section__form__number-of-guest"
          >
            *This invitation only valid for a single person.
          </div>
          <div class="rsvp-section__form__submit">
            <button @click="submit">Confirm</button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script src="./ts/rsvp-section.ts"></script>

<style lang="scss" scoped>
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.rsvp-section {
  background-color: $color-primary;
  color: white;
  &__wrapper {
    margin: auto;
    width: 100%;
    max-width: 700px;
  }
  &__title {
    @include section-title;
  }
  &__name {
    &__value {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  &__form {
    &__number-of-guest {
      margin-top: 24px;
    }
    &__submit {
      margin-top: 16px;
    }
    &__confirmation {
      margin-top: 24px;
      &__item {
        &--holy-matrimony {
          :deep(.attendance-toggle) {
            filter: hue-rotate(200deg);
          }
        }
      }
      &__separator {
        margin: 24px 0;
        max-width: 100%;
      }
    }
  }
  &__thank-you__edit-button,
  &__form__submit {
    text-align: right;
    button {
      cursor: pointer;
      background-color: white;
      color: $color-primary;
      outline: none;
      border-radius: 8px;
      box-shadow: none;
      border: none;
      padding: 8px 12px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .rsvp-section {
    &__form {
      &__confirmation {
        display: flex;
        gap: 24px;
        &__item {
          margin-top: 0;
          flex: 1;
        }
        &__separator {
          display: none;
        }
      }
    }
  }
}
</style>
