<template>
  <div class="rsvp-item">
    <div class="rsvp-item__attendance">
      <div
        class="attendance-toggle"
        :class="{ on: attendance }"
        @click="toggleAttendance"
      >
        <div class="attendance-toggle__option" :class="{ chosen: attendance }">
          Yes
        </div>
        <div class="attendance-toggle__option" :class="{ chosen: !attendance }">
          No
        </div>
        <img
          src="@/assets/images/sakura-blush.png"
          alt=""
          class="attendance-toggle__toggler"
        />
      </div>
    </div>
    <div
      v-if="attendance && maxNumberOfGuests > 1"
      class="rsvp-item__number-of-guest"
    >
      How many of you will attend?
      <div class="rsvp-item__number-of-guest__input">
        <button
          :class="{ disabled: numberOfGuests <= 1 }"
          @click="subtractGuestNumber"
        >
          <q-icon name="remove" />
        </button>
        <div>
          {{ numberOfGuests }}
        </div>
        <button
          :class="{ disabled: numberOfGuests >= maxNumberOfGuests }"
          @click="addGuestNumber"
        >
          <q-icon name="add" />
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./ts/rsvp-item.ts"></script>

<style lang="scss" scoped>
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.rsvp-item {
  &__attendance {
    margin-top: 8px;
    .attendance-toggle {
      margin-left: 4px;
      position: relative;
      height: 24px;
      width: 68px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 8px;
      border-radius: 20px;
      background-color: #898989;
      cursor: pointer;
      &__option {
        opacity: 0;
        transition: opacity 0.2s ease;
        &.chosen {
          opacity: 1;
        }
      }
      &__toggler {
        position: absolute;
        height: 40px;
        width: auto;
        left: -5px;
        filter: grayscale(1);
        transition: all 0.2s ease;
      }
      &.on {
        background-color: #a87a73;
        .attendance-toggle__toggler {
          left: calc(100% - 35px);
          filter: grayscale(0);
        }
      }
    }
  }
  &__number-of-guest {
    margin-top: 16px;
    &__input {
      $height: 24px;
      display: flex;
      height: $height;
      width: 100px;
      align-items: center;
      margin-top: 4px;
      div {
        flex: 1;
        text-align: center;
      }
      button {
        height: $height;
        width: $height;
        border: none;
        border-radius: 8px;
        padding: 0;
        display: flex;
        cursor: pointer;
        & > i {
          font-size: 10px;
          margin: auto;
        }
        &.disabled {
          background-color: #ddd;
          color: #898989;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
