import "@quasar/extras/eva-icons/eva-icons.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import "@quasar/extras/ionicons-v4/ionicons-v4.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import "@quasar/extras/roboto-font/roboto-font.css";
import "./styles/quasar.scss";

import { Notify } from "quasar";

// To be used on app.use(Quasar, { ... })
const options: Record<string, any> = {
  config: {},
  plugins: { Notify },
};

export default options;
