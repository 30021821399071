<template>
  <div class="holy-matrimony-section">
    <div class="holy-matrimony-section__title">Holy Matrimony</div>
    <div class="holy-matrimony-section__item">
      <q-icon name="calendar_today" size="md" color="white" />
      <div>Saturday, 6<sup>th</sup> January 2024</div>
    </div>
    <div class="holy-matrimony-section__item">
      <q-icon name="watch" size="md" color="white" />
      <div>11.00 WIB</div>
    </div>
    <div v-if="visibleLocation" class="holy-matrimony-section__item">
      <q-icon name="place" size="md" color="white" />
      <div>GKI Samanhudi</div>
    </div>
    <div v-else-if="invitationType === 3" class="holy-matrimony-section__gap" />
    <div class="holy-matrimony-section__buttons">
      <q-btn
        v-if="visibleLocation"
        color="white"
        text-color="black"
        href="https://maps.app.goo.gl/Nu9JgvuGRXT21Nyi9"
        target="_blank"
      >
        Open in Google Maps
      </q-btn>
      <q-btn
        v-if="youtubeLink"
        color="white"
        text-color="black"
        :href="youtubeLink"
        target="_blank"
      >
        Watch live streaming
      </q-btn>
      <div v-else class="holy-matrimony-section__buttons__disclaimer">
        Live streaming link will appear here, please check again later.
      </div>
    </div>
  </div>
</template>

<script src="./ts/holy-matrimony-section.ts"></script>

<style scoped lang="scss">
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.holy-matrimony-section {
  &__title {
    border-top: white double 4px;
    border-bottom: white double 4px;
    text-align: center;
    padding: 4px;
  }
  &__item {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
  }
  &__buttons {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    & > *:not(div) {
      flex: 1;
      white-space: nowrap;
    }
    &__disclaimer {
      font-size: 12px;
      line-height: 16px;
      margin: auto;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

@media only screen and (min-width: 820px) {
  .holy-matrimony-section {
    &__gap {
      height: 56px;
    }
  }
}
</style>
