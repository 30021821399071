import { GalleryImage, PaymentMethod } from "./types";

export const K_HOLY_MATRIMONY_DATE_STRING = "2024-01-06T11:00:00.000+07:00";
export const K_WEDDING_GIFT_PAYMENT_METHODS: PaymentMethod[] = [
  {
    label: "Bank BCA",
    accountNumber: "7630296336",
    accountName: "Andika Kusuma",
  },
  {
    label: "OVO",
    accountNumber: "081219790797",
    accountName: "Andika Kusuma",
  },
  {
    label: "GoPay",
    accountNumber: "081219790797",
    accountName: "Andika K",
  },
];
export const K_GALLERY_IMAGES: GalleryImage[] = [
  {
    filename: "casual-02.jpg",
  },
  {
    filename: "casual-03.jpg",
  },
  {
    filename: "casual-04.jpg",
  },
  {
    filename: "casual-05.jpg",
  },
  {
    filename: "casual-06.jpg",
  },
  {
    filename: "casual-08.jpg",
  },
  {
    filename: "casual-10.jpg",
  },
  {
    filename: "formal-02.jpg",
  },
  {
    filename: "formal-03.jpg",
  },
  {
    filename: "formal-05.jpg",
  },
  {
    filename: "formal-06.jpg",
  },
  {
    filename: "grey-01.jpg",
  },
  {
    filename: "grey-02.jpg",
  },
  {
    filename: "grey-03.jpg",
  },
  {
    filename: "grey-04.jpg",
  },
  {
    filename: "grey-05.jpg",
  },
  {
    filename: "grey-06.jpg",
  },
  {
    filename: "dark-01.jpg",
  },
  {
    filename: "dark-02.jpg",
  },
  {
    filename: "dark-03.jpg",
  },
  {
    filename: "dark-04.jpg",
  },
  {
    filename: "casual-01.jpg",
  },
  {
    filename: "casual-07.jpg",
  },
  {
    filename: "casual-09.jpg",
  },
  {
    filename: "casual-11.jpg",
  },
  {
    filename: "casual-12.jpg",
  },
  {
    filename: "formal-04.jpg",
  },
  {
    filename: "dark-05.jpg",
  },
];
