export const convertTimeToCountdown = (time: number) => {
  const timeInSeconds = Math.floor(time / 1000);
  const seconds = timeInSeconds % 60;
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  let hours = Math.floor(timeInSeconds / 3600) % 24;
  let days = Math.floor(timeInSeconds / 86400);
  if (days < 2) {
    hours += days * 24;
    days = 0;
  }
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
