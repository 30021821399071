import { ProvidedGuestDetail } from "@/api/guest/types";
import { computed, defineComponent, inject } from "vue";

export default defineComponent({
  name: "HomePage",
  setup() {
    const { guestDetail } = inject("guestDetail") as ProvidedGuestDetail;

    const menus = computed(() => {
      const _menus = [
        {
          text: "Profile",
          className: "profile-page",
        },
        {
          text: "Event",
          className: "save-the-date-section",
        },
      ];
      if (guestDetail.value.maxNumberOfGuests > 0) {
        _menus.push({
          text: "RSVP",
          className: "rsvp-section",
        });
      }
      return _menus;
    });

    const warpTo = (className: string) => {
      document.getElementsByClassName(className)[0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    };

    return {
      menus,
      warpTo,
    };
  },
});
