<template>
  <div class="envelope">
    <div class="envelope__background">
      <!-- <img src="@/assets/images/envelope-mobile.jpg" alt="" /> -->
    </div>
    <div class="envelope__content">
      The wedding of
      <div class="envelope__content__name">Andika & Gisela</div>
      <div>Dear {{ guestName }},</div>
      <div>Please celebrate with us</div>
      <button class="envelope__content__button" @click="openEnvelope">
        Open Invitation
      </button>
    </div>
  </div>
</template>
<script src="./ts/invitation-envelope.ts"></script>

<style lang="scss">
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.envelope {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $color-base;
  background: radial-gradient($color-base 50%, $color-base-dark);
  box-shadow: 0 0 18px -6px rgba(0, 0, 0, 0.38);
  &::after {
    content: "";
    background: linear-gradient(0deg, $color-base 10%, transparent);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
  }
  &__background {
    position: relative;
    width: 100%;
    background-image: url("@/assets/images/envelope-mobile.jpg");
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 20%;
    img {
      width: 100%;
    }
    &::after {
      content: "";
      background: linear-gradient(0deg, $color-base 10%, transparent);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 320px;
    }
  }
  &__content {
    text-align: center;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 90%;
    z-index: 1;
    &__name {
      @include font-euphoria;
      font-size: 52px;
      line-height: 1;
      white-space: nowrap;
    }
    &__button {
      border: none;
      background-color: $color-primary;
      border-radius: 8px;
      padding: 8px 12px;
      color: $color-base;
      box-shadow: 0 0 6px -2px $color-primary;
      cursor: pointer;
      margin-top: 8px;
    }
  }
}
@media only screen and (orientation: landscape) {
  .envelope {
    &::after {
      background: linear-gradient(-90deg, $color-base 10%, transparent);
      position: absolute;
      top: 0;
      left: initial;
      right: 0;
      width: 40%;
      height: 100%;
    }
    &__background {
      background-image: url("@/assets/images/envelope-desktop.jpg");
      &::after {
        background: linear-gradient(-90deg, $color-base 10%, transparent);
        position: absolute;
        top: 0;
        left: initial;
        right: 0;
        width: 40%;
        height: 100%;
      }
    }
    &__content {
      bottom: initial;
      top: 50%;
      left: initial;
      right: 4vw;
      transform: translateY(-50%);
      max-width: 400px;
      &__button {
        margin-top: 16px;
      }
    }
  }
}
</style>
