import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import { PaymentMethod } from "@/types";
import { Notify } from "quasar";
import { PropType, defineComponent, ref } from "vue";

export default defineComponent({
  name: "PaymentMethodItem",
  components: {
    ChevronIcon,
    CopyIcon,
  },
  props: {
    paymentMethod: {
      type: Object as PropType<PaymentMethod>,
      required: true,
    },
  },
  setup(props) {
    const isExpanded = ref(false);
    const actionClickHeader = () => {
      isExpanded.value = !isExpanded.value;
    };
    const actionCopyAccountNumber = () => {
      navigator.clipboard.writeText(props.paymentMethod.accountNumber);
      Notify.create(
        `Successfully copy account number from ${props.paymentMethod.label}`
      );
    };

    return {
      actionClickHeader,
      actionCopyAccountNumber,
      isExpanded,
    };
  },
});
