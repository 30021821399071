<template>
  <div class="save-the-date-section">
    <div class="save-the-date-section__title">Save the Date</div>
    <div class="save-the-date-section__content">
      <HolyMatrimonySection />
      <WeddingReceptionSection v-if="guestDetail.invitationType > 2" />
    </div>
  </div>
</template>

<script src="./ts/save-the-date-section.ts"></script>

<style scoped lang="scss">
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.save-the-date-section {
  background-color: $color-primary;
  color: white;
  background-image: url("@/assets/images/background/sakura.png");
  animation: fallingSakura 8s linear infinite;
  background-repeat: repeat;
  &__title {
    @include section-title;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: flex-start;
    & > * {
      width: 600px;
      max-width: 100%;
      margin: auto;
    }
  }
}

@media only screen and (min-width: 820px) {
  .save-the-date-section {
    gap: 24px;
    &__content {
      flex-direction: row;
      justify-content: center;
      & > * {
        flex: 1;
        max-width: 600px;
        margin: 0;
      }
    }
  }
}
@keyframes fallingSakura {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 400px;
  }
}
</style>
