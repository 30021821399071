<template>
  <div class="image-preview">
    <q-icon
      name="close"
      size="md"
      class="image-preview__close"
      @click.stop="close"
    />
    <ChevronIcon
      class="image-preview__navigation image-preview__navigation--prev"
      @click.stop="previousImage"
    />
    <ChevronIcon
      class="image-preview__navigation image-preview__navigation--next"
      @click.stop="nextImage"
    />
    <img :src="currentImage.uri" alt="" />
    <div class="image-preview__pagination">
      {{ currentIndex + 1 }}
      /
      {{ images.length }}
    </div>
  </div>
</template>
<script src="./ts/image-preview.ts"></script>

<style lang="scss">
@import "@/assets/scss/tokens";
.image-preview {
  $shadow-filter: drop-shadow(0px 0px 2px #818181);
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  &__close {
    position: absolute !important;
    top: 8px;
    right: 8px;
    color: white;
    filter: $shadow-filter;
  }
  &__navigation {
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    color: white;
    filter: $shadow-filter;
    &--prev {
      left: 8px;
      transform: rotate(-180deg);
    }
    &--next {
      right: 8px;
    }
  }
  &__pagination {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%);
    color: white;
    filter: $shadow-filter;
  }
}
</style>
