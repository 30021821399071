<template>
  <div class="wish-section">
    <div class="wish-section__title">Wedding Wish</div>
    <div class="wish-section__content">
      <input
        v-if="!guestName"
        v-model="name"
        placeholder="Input your name here"
        class="wish-section__content__name-input"
      />
      <div class="wish-section__content__wish-box">
        <textarea v-model="wish" placeholder="Input your wish here" />
        <div v-if="guestName" class="wish-section__content__wish-box__name">
          - {{ guestName }} -
        </div>
      </div>
      <div class="wish-section__content__submit">
        <button :disabled="!savedName || !wish" @click="submit">
          Send wishes
        </button>
      </div>
    </div>
    <div v-if="guestWishes.length" class="wishes-wrapper">
      <div class="wish-section__wishes">
        <div
          v-for="(guestWish, index) in guestWishes"
          :key="`${guestWish.name}--${index}`"
          class="wish-section__wishes__item"
        >
          <div class="wish-section__wishes__item__name">
            {{ guestWish.name }}
          </div>
          <div class="wish-section__wishes__item__value">
            {{ guestWish.wish }}
          </div>
        </div>
      </div>
      <img
        src="@/assets/images/background/silhouette.png"
        class="wish-section__wishes__background"
        alt=""
      />
    </div>
  </div>
</template>
<script src="./ts/wish-section.ts"></script>

<style lang="scss" scoped>
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.wish-section {
  background-color: $color-green;
  &__title {
    @include section-title;
  }
  &__content {
    width: 700px;
    max-width: 100%;
    margin: auto;
    &__name-input {
      border: none;
      background-color: rgba(255, 255, 255, 60);
      border-radius: 8px;
      padding: 8px;
      margin-bottom: 8px;
      width: 100%;
    }
    &__wish-box {
      position: relative;
      textarea {
        width: 100%;
        resize: none;
        height: 100px;
        border: none;
        background-color: rgba(255, 255, 255, 60);
        border-radius: 8px;
        padding: 8px 8px 24px;
      }
      &__name {
        position: absolute;
        right: 8px;
        bottom: 8px;
        background-color: white;
        border-radius: 4px;
        padding: 0 4px;
      }
    }
    &__submit {
      text-align: right;
      margin-top: 8px;
      button {
        cursor: pointer;
        background-color: $color-primary;
        color: white;
        outline: none;
        border-radius: 8px;
        box-shadow: none;
        border: none;
        padding: 8px 12px;
      }
    }
  }
  .wishes-wrapper {
    position: relative;
  }
  &__wishes {
    margin: 16px auto 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    &__background {
      position: absolute;
      bottom: 0;
      right: 0;
      object-fit: contain;
      width: auto;
      max-width: 80%;
      max-height: 90%;
      opacity: 0.15;
      pointer-events: none;
    }
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 14px 14px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background-color: #371a0855;
    }
    &__item {
      border-left: 2px solid $color-primary;
      padding-left: 8px;
      width: 700px;
      max-width: 100%;
      margin: auto;
      &__name {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
