import MediaPlayer from "@/components/MediaPlayer.vue";
import { QBtn } from "quasar";
import uuid from "uuid-random";
import { defineComponent, onBeforeUnmount, onMounted, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { loadGuestDetail } from "./api/guest/api";
import { GuestDetail } from "./api/guest/types";
import InvitationContent from "./components/InvitationContent.vue";
import InvitationEnvelope from "./components/InvitationEnvelope.vue";
import { APIError, APIResponse } from "./types";

export default defineComponent({
  name: "App",
  components: {
    InvitationContent,
    InvitationEnvelope,
    MediaPlayer,
    Button: QBtn,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const visibleEnvelope = ref(true);
    const visibleMediaPlayer = ref(true);
    const mediaPlayer = ref();
    const scrollTimeout = ref();
    const lastScrollPosition = ref(document.documentElement.scrollTop);
    const guestDetail = ref<GuestDetail | APIError | null>({
      autoFillWishName: false,
      name: "",
      key: uuid(),
      invitationType: 0,
      maxNumberOfGuests: 0,
      wish: null,
      rsvp: null,
    });

    const registerMediaPlayer = (player: any) => {
      mediaPlayer.value = player;
    };
    const openEnvelope = () => {
      window.scroll({ left: 0, top: 0 });
      visibleEnvelope.value = false;
      mediaPlayer.value.play();
    };
    const updateGuestDetail = (updates: any) => {
      guestDetail.value = {
        ...guestDetail.value,
        ...updates,
      };
    };
    provide("guestDetail", { guestDetail, updateGuestDetail });

    const listenScrollEvent = () => {
      clearTimeout(scrollTimeout.value);
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      visibleMediaPlayer.value =
        lastScrollPosition.value > currentScrollPosition;
      lastScrollPosition.value = currentScrollPosition;
      scrollTimeout.value = setTimeout(() => {
        visibleMediaPlayer.value = true;
      }, 500);
    };

    onMounted(async () => {
      await router.isReady();
      document.addEventListener("scroll", listenScrollEvent);
      const key = (route.params.key as string) || "";
      if (!key) {
        return;
      }
      loadGuestDetail(key).then(
        (res: APIResponse<GuestDetail>) => (guestDetail.value = res)
      );
    });
    onBeforeUnmount(() => {
      clearTimeout(scrollTimeout.value);
      document.removeEventListener("scroll", listenScrollEvent);
    });

    return {
      visibleEnvelope,
      visibleMediaPlayer,
      mediaPlayer,
      registerMediaPlayer,
      openEnvelope,
    };
  },
});
