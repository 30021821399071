import ChevronIcon from "@/components/icons/ChevronIcon.vue";
import { GalleryImage } from "@/types";
import { PropType, computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "ImagePreview",
  components: {
    ChevronIcon,
  },
  props: {
    images: {
      type: Object as PropType<GalleryImage[]>,
      required: true,
    },
    initialIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentIndex = ref(props.initialIndex);
    const currentImage = computed(() => props.images[currentIndex.value]);
    const imagesCount = computed(() => props.images.length);

    const previousImage = () => {
      currentIndex.value =
        (currentIndex.value + imagesCount.value - 1) % imagesCount.value;
    };
    const nextImage = () => {
      currentIndex.value = (currentIndex.value + 1) % imagesCount.value;
    };
    const close = () => {
      emit("close");
    };

    return {
      currentIndex,
      currentImage,
      previousImage,
      nextImage,
      close,
    };
  },
});
