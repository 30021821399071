<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="800"
    height="800"
    viewBox="0 0 169.06 169.06"
  >
    <path
      d="M122.4 0H46.66A46.7 46.7 0 0 0 0 46.66v75.75a46.7 46.7 0 0 0 46.65 46.65h75.76a46.7 46.7 0 0 0 46.65-46.65V46.66A46.7 46.7 0 0 0 122.41 0zm31.66 122.4a31.7 31.7 0 0 1-31.65 31.66H46.65A31.69 31.69 0 0 1 15 122.41V46.66A31.7 31.7 0 0 1 46.65 15h75.76a31.7 31.7 0 0 1 31.65 31.66v75.75z"
    />
    <path
      d="M84.53 40.97a43.61 43.61 0 0 0-43.56 43.56c0 24.02 19.54 43.56 43.56 43.56s43.56-19.54 43.56-43.56a43.61 43.61 0 0 0-43.56-43.56zm0 72.12a28.6 28.6 0 0 1-28.56-28.56c0-15.75 12.81-28.56 28.56-28.56s28.56 12.81 28.56 28.56a28.6 28.6 0 0 1-28.56 28.56zM129.92 28.25a11.04 11.04 0 0 0-11 11c0 2.9 1.18 5.73 3.23 7.78a11.08 11.08 0 0 0 7.77 3.22c2.9 0 5.73-1.18 7.78-3.22a11.05 11.05 0 0 0 0-15.56 11.05 11.05 0 0 0-7.78-3.22z"
    />
  </svg>
</template>
