import { K_GALLERY_IMAGES } from "@/constants";
import { defineComponent, ref } from "vue";

import ImagePreview from "@/components/commons/ImagePreview.vue";

export default defineComponent({
  name: "GallerySection",
  components: {
    ImagePreview,
  },
  setup() {
    const galleryImages = K_GALLERY_IMAGES.map((image) => ({
      ...image,
      uri: require(`../../../assets/images/gallery/${image.filename}`),
    }));
    const selectedIndex = ref(0);
    const isPreviewOpened = ref(false);

    const previewImage = (index: number) => {
      selectedIndex.value = index;
      isPreviewOpened.value = true;
      document.body.style.overflow = "hidden";
    };

    const closePreview = () => {
      isPreviewOpened.value = false;
      document.body.style.overflow = "";
    };

    return {
      galleryImages,
      selectedIndex,
      isPreviewOpened,
      closePreview,
      previewImage,
    };
  },
});
