import InstagramIcon from "@/components/icons/InstagramIcon.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProfileSection",
  components: {
    InstagramIcon,
  },
  setup() {
    const goToIgAccount = (instagramAccount: string) => {
      window
        .open(
          `https://www.instagram.com/${instagramAccount.substring(1)}`,
          "_blank"
        )
        ?.focus();
    };

    return {
      groom: {
        name: "Andika Kusuma",
        description:
          "Third son of <span class='name'>Mr. Ang Tek Bok</span> & <span class='name'>Mrs. Lim So Hun</span>",
        instagramAccount: "@dikaang",
      },
      bride: {
        name: "Gisela Supardi",
        description:
          "Second daughter of <span class='name'>Mr. Henry Supardi</span> & <span class='name'>Mrs. Theresia C. Tandri</span>",
        instagramAccount: "@giselasupardi",
      },
      goToIgAccount,
    };
  },
});
