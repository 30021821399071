import { ProvidedGuestDetail } from "@/api/guest/types";
import { computed, defineComponent, inject } from "vue";

export default defineComponent({
  name: "HolyMatrimonySection",
  setup() {
    const { guestDetail } = inject("guestDetail") as ProvidedGuestDetail;

    const invitationType = computed(() => guestDetail.value.invitationType);
    const visibleLocation = computed(
      () => invitationType.value === 2 || invitationType.value === 4
    );

    return {
      youtubeLink: "https://www.youtube.com/watch?v=G6FisOk8Fog",
      invitationType,
      visibleLocation,
    };
  },
});
