import dev from "./config.dev";
import prod from "./config.prod";

let config: Record<string, string>;
if (process.env.VUE_APP_ENV === "prod") {
  config = prod;
} else {
  config = dev;
}

export default config;
