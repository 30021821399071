<template>
  <div class="countdown-section">
    <div class="countdown-section__title">
      {{ title }}
    </div>
    <div class="countdown-section__content">
      <div v-if="countdown.days > 0" class="countdown-section__content__box">
        <div class="countdown-section__content__box__value">
          {{ countdown.days }}
        </div>
        <div class="countdown-section__content__box__label">days</div>
      </div>
      <div class="countdown-section__content__box">
        <div class="countdown-section__content__box__value">
          {{ formatTime(countdown.hours) }}
        </div>
        <div class="countdown-section__content__box__label">hours</div>
      </div>
      <div class="countdown-section__content__box">
        <div class="countdown-section__content__box__value">
          {{ formatTime(countdown.minutes) }}
        </div>
        <div class="countdown-section__content__box__label">minutes</div>
      </div>
      <div class="countdown-section__content__box">
        <div class="countdown-section__content__box__value">
          {{ formatTime(countdown.seconds) }}
        </div>
        <div class="countdown-section__content__box__label">seconds</div>
      </div>
    </div>
  </div>
</template>
<script src="./ts/countdown-section.ts"></script>

<style lang="scss">
@import "@/assets/scss/tokens";
.countdown-section {
  background-color: white;
  &__title {
    text-align: center;
    margin-bottom: 16px;
  }
  &__content {
    display: flex;
    flex: 1;
    gap: 16px;
    justify-content: center;
    &__box {
      $dimension: 64px;
      border: 1px solid $color-primary;
      border-radius: 12px;
      padding: 8px 0;
      width: $dimension;
      height: $dimension;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.65);
      z-index: 1;
      &__value {
        font-size: 28px;
        line-height: 32px;
      }
      &__label {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
