import config from "@/config";
import { APIResponse } from "@/types";
import superagent from "superagent";
import {
  CreateGuestRSVPPayload,
  GuestDetail,
  GuestWish,
  WishPayload,
} from "./types";

export const upsertGuestWish = async (payload: WishPayload) => {
  try {
    const res = await superagent
      .post(`${config.API_URL_GUEST_BACKEND}/guests/wishes/`)
      .send(payload);
    return res.body;
  } catch (err) {
    console.error(err);
    throw "Terjadi Kesalahan";
  }
};

export const createGuestRSVP = async (payload: CreateGuestRSVPPayload) => {
  try {
    const res = await superagent
      .post(`${config.API_URL_GUEST_BACKEND}/guests/rsvp/`)
      .send(payload);
    return res.body;
  } catch (err) {
    console.error(err);
    throw "Terjadi Kesalahan";
  }
};

export const loadGuestDetail = async (
  guestKey: string
): Promise<APIResponse<GuestDetail>> => {
  try {
    const res = await superagent.get(
      `${config.API_URL_GUEST_BACKEND}/guests/${guestKey}/`
    );
    return res.body;
  } catch (err) {
    console.error(err);
    throw "Terjadi Kesalahan";
  }
};

export const loadGuestWishesList = async (): Promise<
  APIResponse<GuestWish[]>
> => {
  try {
    const res = await superagent.get(
      `${config.API_URL_GUEST_BACKEND}/guests/wishes/`
    );
    return res.body;
  } catch (err) {
    console.error(err);
    throw "Terjadi Kesalahan";
  }
};
