import { ProvidedGuestDetail } from "@/api/guest/types";
import { computed, defineComponent, inject } from "vue";

export default defineComponent({
  name: "InvitationEnvelope",
  setup(_, { emit }) {
    const { guestDetail } = inject("guestDetail") as ProvidedGuestDetail;
    const guestName = computed(() => guestDetail?.value?.name || "Guest");

    const openEnvelope = () => {
      emit("open");
    };

    return {
      guestName,
      openEnvelope,
    };
  },
});
