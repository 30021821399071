<template>
  <div class="payment-method-item__container">
    <div class="payment-method-item__header" @click="actionClickHeader">
      {{ paymentMethod.label }}
      <ChevronIcon
        class="payment-method-item__header__chevron"
        :class="isExpanded ? 'expanded' : 'collapsed'"
      />
    </div>
    <div v-if="isExpanded" class="payment-method-item__content">
      <div
        class="payment-method-item__content__account-number"
        @click="actionCopyAccountNumber"
      >
        {{ paymentMethod.accountNumber }}
        <CopyIcon class="payment-method-item__content__account-number__icon" />
      </div>
      <div>a/n {{ paymentMethod.accountName }}</div>
    </div>
  </div>
</template>
<script src="./ts/payment-method-item.ts"></script>

<style lang="scss">
@import "@/assets/scss/tokens";
.payment-method-item {
  &__container {
    box-shadow: $default-shadow;
    border-radius: 8px;
    padding: 8px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    cursor: pointer;
    &__chevron {
      width: 18px;
      height: 18px;
      transition: transform 0.2s ease;
      &.collapsed {
        transform: rotate(90deg);
      }
      &.expanded {
        transform: rotate(-90deg);
      }
    }
  }
  &__content {
    text-align: center;
    &__account-number {
      display: flex;
      flex-direction: row;
      justify-content: center;
      &__icon {
        margin-left: 4px;
        height: 18px;
      }
    }
  }
}
</style>
