import { ProvidedGuestDetail } from "@/api/guest/types";
import { computed, defineComponent, inject } from "vue";

export default defineComponent({
  name: "RsvpItem",
  props: {
    attendance: {
      type: Boolean,
      required: true,
    },
    numberOfGuests: {
      type: Number,
      required: true,
    },
  },
  async setup(props, { emit }) {
    const { guestDetail } = inject("guestDetail") as ProvidedGuestDetail;
    const maxNumberOfGuests = computed(
      () => guestDetail?.value?.maxNumberOfGuests || 1
    );

    const toggleAttendance = () => {
      const isAttend = !props.attendance;
      emit("update:attendance", isAttend);
      if (isAttend) {
        emit("update:numberOfGuests", maxNumberOfGuests.value);
      }
    };
    const subtractGuestNumber = () => {
      if (props.numberOfGuests === 1) {
        return;
      }
      emit("update:numberOfGuests", props.numberOfGuests - 1);
    };
    const addGuestNumber = () => {
      if (props.numberOfGuests === maxNumberOfGuests.value) {
        return;
      }
      emit("update:numberOfGuests", props.numberOfGuests + 1);
    };

    return {
      maxNumberOfGuests,
      toggleAttendance,
      subtractGuestNumber,
      addGuestNumber,
    };
  },
});
