<template>
  <div class="gallery-section">
    <div class="gallery-section__title">Gallery</div>
    <div class="gallery-section__content">
      <div
        class="gallery-section__content__images"
        v-for="(galleryImage, index) in galleryImages"
        :key="galleryImage.uri"
      >
        <img :src="galleryImage.uri" @click="previewImage(index)" />
      </div>
    </div>
    <ImagePreview
      v-if="isPreviewOpened"
      :images="galleryImages"
      :initial-index="selectedIndex"
      @close="closePreview"
    />
  </div>
</template>
<script src="./ts/gallery-section.ts"></script>

<style lang="scss">
@import "@/assets/scss/tokens";
@import "@/assets/scss/font";
.gallery-section {
  background-color: white;
  background-image: url("@/assets/images/background/leaves.png");
  background-repeat: repeat;
  &__title {
    @include section-title;
  }
  &__content {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    &__images {
      cursor: pointer;
      height: 150px;
      img {
        height: 100%;
        width: auto;
        border-radius: 12px;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .gallery-section {
    &__content {
      &__images {
        height: 250px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .gallery-section {
    &__content {
      &__images {
        height: 300px;
      }
    }
  }
}
</style>
