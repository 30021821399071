import { createGuestRSVP } from "@/api/guest/api";
import { ProvidedGuestDetail } from "@/api/guest/types";
import RsvpItem from "@/components/contents/RsvpItem.vue";
import { computed, defineComponent, inject, ref, watch } from "vue";

export default defineComponent({
  name: "RsvpSection",
  components: { RsvpItem },
  async setup() {
    const { guestDetail, updateGuestDetail } = inject(
      "guestDetail"
    ) as ProvidedGuestDetail;
    const editing = ref<boolean>(false);
    const maxNumberOfGuests = computed(
      () => guestDetail?.value?.maxNumberOfGuests || 1
    );
    const churchNumberOfGuests = ref<number>(
      guestDetail?.value?.rsvp?.churchNumberOfGuests || maxNumberOfGuests.value
    );
    const receptionNumberOfGuests = ref<number>(
      guestDetail?.value?.rsvp?.receptionNumberOfGuests ||
        maxNumberOfGuests.value
    );
    const visibleHolyMatrimonySection = computed(
      () =>
        guestDetail.value?.invitationType === 2 ||
        guestDetail.value?.invitationType === 4
    );
    const visibleReceptionSection = computed(
      () =>
        guestDetail.value?.invitationType === 3 ||
        guestDetail.value?.invitationType === 4
    );
    const churchAttendance = ref<boolean>(
      guestDetail?.value?.rsvp
        ? guestDetail.value.rsvp.churchAttendance
        : visibleHolyMatrimonySection.value
    );
    const receptionAttendance = ref<boolean>(
      guestDetail?.value?.rsvp
        ? guestDetail.value.rsvp.receptionAttendance
        : visibleReceptionSection.value
    );

    const startEdit = () => {
      editing.value = true;
    };
    const submit = () => {
      const churchGuestCount = churchAttendance.value
        ? churchNumberOfGuests.value
        : 0;
      const receptionGuestCount = receptionAttendance.value
        ? receptionNumberOfGuests.value
        : 0;
      if (
        guestDetail.value.rsvp &&
        churchGuestCount === guestDetail.value.rsvp.churchNumberOfGuests &&
        churchAttendance.value === guestDetail.value.rsvp.churchAttendance &&
        receptionGuestCount ===
          guestDetail.value.rsvp.receptionNumberOfGuests &&
        receptionAttendance.value === guestDetail.value.rsvp.receptionAttendance
      ) {
        editing.value = false;
        return;
      }
      createGuestRSVP({
        churchAttendance: churchAttendance.value,
        churchNumberOfGuests: churchGuestCount,
        guestKey: guestDetail?.value.key || "",
        receptionNumberOfGuests: receptionGuestCount,
        receptionAttendance: !!receptionAttendance.value,
      }).then(() => {
        updateGuestDetail({
          rsvp: {
            churchAttendance: churchAttendance.value,
            churchNumberOfGuests: churchGuestCount,
            receptionNumberOfGuests: receptionGuestCount,
            receptionAttendance: !!receptionAttendance.value,
          },
        });
        editing.value = false;
      });
    };

    const unwatchGuestDetail = watch(
      () => guestDetail.value,
      () => {
        if (guestDetail.value.rsvp) {
          churchNumberOfGuests.value =
            guestDetail.value.rsvp.churchNumberOfGuests;
          churchAttendance.value = guestDetail.value.rsvp.churchAttendance;
          receptionNumberOfGuests.value =
            guestDetail.value.rsvp.receptionNumberOfGuests;
          receptionAttendance.value =
            guestDetail.value.rsvp.receptionAttendance;
        } else {
          churchNumberOfGuests.value = maxNumberOfGuests.value || 1;
          receptionNumberOfGuests.value = maxNumberOfGuests.value || 1;
        }
        unwatchGuestDetail();
      }
    );

    return {
      guestDetail,
      maxNumberOfGuests,
      editing,
      churchAttendance,
      churchNumberOfGuests,
      receptionAttendance,
      receptionNumberOfGuests,
      visibleHolyMatrimonySection,
      visibleReceptionSection,
      startEdit,
      submit,
    };
  },
});
