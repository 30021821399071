<template>
  <div class="profile-page">
    <div class="profile-page__introduction">
      You are cordially invited to the wedding of
    </div>
    <div class="profile-page__content">
      <div class="profile-page__bio profile-page__bio--groom">
        <div class="profile-page__bio__photo">
          <img src="@/assets/images/profile-Dika.jpg" alt="Dika" />
        </div>
        <div class="profile-page__bio__data">
          <div class="profile-page__bio__data__name">
            {{ groom.name }}
          </div>
          <div
            class="profile-page__bio__data__desc"
            v-html="groom.description"
          />
          <div
            class="profile-page__bio__data__ig"
            @click="goToIgAccount(groom.instagramAccount)"
          >
            <InstagramIcon />
            {{ groom.instagramAccount }}
          </div>
        </div>
      </div>
      <div class="profile-page__separator">&</div>
      <div class="profile-page__bio profile-page__bio--bride">
        <div class="profile-page__bio__photo">
          <img src="@/assets/images/profile-Gisela.jpg" alt="Gisela" />
        </div>
        <div class="profile-page__bio__data">
          <div class="profile-page__bio__data__name">
            {{ bride.name }}
          </div>
          <div
            class="profile-page__bio__data__desc"
            v-html="bride.description"
          />
          <div
            class="profile-page__bio__data__ig"
            @click="goToIgAccount(bride.instagramAccount)"
          >
            <InstagramIcon />
            {{ bride.instagramAccount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./ts/profile-section.ts"></script>

<style lang="scss" scoped>
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.profile-page {
  --page-padding: 24px;
  background-color: white;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.2;
    pointer-events: none;
  }
  &::before {
    right: 0;
    top: 0;
    width: 200px;
    height: 100%;
    background-image: url("@/assets/images/background/branch.png");
  }
  &::after {
    left: 10px;
    bottom: -150px;
    width: 250px;
    height: 280px;
    background-image: url("@/assets/images/background/branch-bottom.png");
  }
  &__introduction {
    text-align: center;
    margin-bottom: 24px;
  }
  &__bio {
    display: flex;
    gap: 8px;
    align-items: center;
    &--groom {
      .profile-page__bio__photo {
        img {
          object-position: 100% 32%;
          height: 100%;
        }
      }
    }
    &--bride {
      flex-direction: row-reverse;
      .profile-page__bio {
        &__photo {
          position: relative;
          img {
            height: 100%;
            transform: scale(2.5);
            position: absolute;
            left: 28px;
            top: 69px;
          }
        }
        &__data {
          text-align: right;
        }
      }
    }
    &__photo {
      flex-shrink: 0;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
    &__data {
      flex: 1;
      min-width: 0;
      &__name {
        @include font-euphoria;
        font-size: 36px;
        line-height: 40px;
      }
      &__desc {
        font-size: 14px;
        line-height: 18px;
        :deep(.name) {
          white-space: nowrap;
        }
      }
      &__ig {
        $height: 16px;
        line-height: $height;
        font-size: $height - 4;
        margin-top: 8px;
        cursor: pointer;
        svg {
          height: $height;
          width: $height;
          display: inline-block;
          vertical-align: text-top;
        }
      }
    }
  }
  &__separator {
    @include font-euphoria;
    display: flex;
    gap: 24px;
    padding: 24px;
    align-items: center;
    font-size: 24px;
    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid $color-primary;
    }
  }
}

@media only screen and (max-width: 375px) {
  .profile-page {
    --page-padding: 24px 12px;
    &__bio {
      &__data {
        &__name {
          font-size: 28px;
          line-height: 32px;
        }
      }
    }
  }
}

@media only screen and (min-width: 820px) {
  .profile-page {
    &__content {
      display: flex;
    }
    &__bio {
      flex: 1;
      &--groom {
        flex-direction: row-reverse;
        .profile-page__bio {
          &__data {
            text-align: right;
          }
        }
      }
      &--bride {
        flex-direction: row;
        .profile-page__bio {
          &__data {
            text-align: left;
          }
        }
      }
      &__data {
        &__desc {
          min-height: 54px;
        }
      }
    }
    &__separator {
      flex-shrink: 0;
      flex-direction: column;
      gap: 8px;
      padding: 16px 24px;
      &:before,
      &:after {
        border-bottom: none;
        border-right: 1px solid $color-primary;
      }
    }
  }
}
</style>
