<template>
  <div class="media-player" :class="{ playing }">
    <img src="@/assets/images/media-player.png" alt="" @click="togglePlayer" />
    <audio ref="audioPlayer">
      <source src="@/assets/music/Until_I_Found_You.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>
<script src="./ts/media-player.ts"></script>

<style lang="scss">
@import "@/assets/scss/tokens";
.media-player {
  --dimension: 60px;
  position: fixed;
  left: 16px;
  bottom: 16px;
  z-index: 1;
  transition: left 0.4s ease-in;
  img {
    width: var(--dimension);
    height: var(--dimension);
    cursor: pointer;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: paused;
  }
  &.playing {
    img {
      animation-play-state: running;
    }
  }
  &.player-hidden {
    left: calc(var(--dimension) * -1);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
