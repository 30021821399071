<template>
  <div class="reception-section">
    <div class="reception-section__title">Reception</div>
    <div class="reception-section__item">
      <q-icon name="calendar_today" size="md" color="white" />
      <div>Saturday, 6<sup>th</sup> January 2024</div>
    </div>
    <div class="reception-section__item">
      <q-icon name="watch" size="md" color="white" />
      <div>18.00 WIB</div>
    </div>
    <div class="reception-section__item">
      <q-icon name="place" size="md" color="white" />
      <div>Royal Jade Restaurant, 3<sup>rd</sup> Floor</div>
    </div>
    <div class="reception-section__buttons">
      <q-btn
        color="white"
        text-color="black"
        href="https://maps.app.goo.gl/zzcrN2P4EiiGNjrt5"
        target="_blank"
      >
        Open in Google Maps
      </q-btn>
    </div>
  </div>
</template>

<script src="./ts/wedding-reception-section.ts"></script>

<style scoped lang="scss">
@import "@/assets/scss/_tokens";
@import "@/assets/scss/font";
.reception-section {
  &__title {
    border-top: white double 4px;
    border-bottom: white double 4px;
    text-align: center;
    padding: 4px;
  }
  &__item {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
  }
  &__buttons {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    & > * {
      flex: 1;
    }
  }
}
</style>
