<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      d="M15.24 2h-3.9c-1.76 0-3.16 0-4.25.15a4.51 4.51 0 0 0-2.75 1.2 4.55 4.55 0 0 0-1.2 2.76C3 7.2 3 8.6 3 10.38v5.84c0 1.5.92 2.8 2.23 3.34-.07-.91-.07-2.19-.07-3.25V11.3c0-1.28 0-2.38.12-3.27a4.3 4.3 0 0 1 1.15-2.6A4.27 4.27 0 0 1 9 4.3c.88-.12 1.98-.12 3.26-.12h3.07c1.27 0 2.37 0 3.25.12A3.6 3.6 0 0 0 15.24 2Z"
    />
    <path
      d="M6.6 11.4c0-2.73 0-4.1.84-4.94.85-.85 2.2-.85 4.92-.85h2.88c2.72 0 4.07 0 4.92.85.84.85.84 2.21.84 4.94v4.82c0 2.72 0 4.09-.84 4.93-.85.85-2.2.85-4.92.85h-2.88c-2.72 0-4.07 0-4.92-.85-.84-.84-.84-2.2-.84-4.93V11.4Z"
    />
  </svg>
</template>
